import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

const shares = {
  namespaced: true,
  state: {
    inviteRequest: "",
    shares: [],
    expenses: [],
    functions: {
      callBackend: async (payload) => {
        let data = {
          return_code: "",
          return_message: "",
          return_message_txt: "",
          return_error: "",
        };
        console.log("F -< callBackend", payload);
        const env =
          process.env.NODE_ENV !== "production"
            ? "/" + process.env.NODE_ENV
            : "";
        const target =
          process.env.NODE_ENV !== "production"
            ? "https://ws2.cybersite.dk"
            : "";

        await axios
          .post(
            target + "/api/wesharetoo/" + payload.function + env,
            payload.data
          )
          .then((result) => {
            console.log("functions.callBackend", result.data);
            data = result.data;
          })
          .catch((err) => {
            console.log("Der skete en generel fejl!");
            console.log(err);
            data.return_code = "failure";
            data.return_message = err.message;
            data.return_error = err;
          });
        return data;
      },
      safeCopy: (source) => {
        return JSON.parse(JSON.stringify(source)); //deep copy
      },
    },
  },
  mutations: {
    //Shares
    createShare(state, payload) {
      console.log("M -> createShare", payload);
      const share = payload.payload;
      share.shareID = payload.key;
      state.shares.push(share);
    },
    loadShares(state, payload) {
      state.shares = state.functions.safeCopy(payload.shares);
      state.shares.map((s) => {
        s.shareID = s._id.$oid;
      });
    },
    joinShare(state, payload) {
      console.log(state.shares, payload);
    },
    //Expenses
    addExpense(state, payload) {
      state.expenses.push(payload);
    },
    loadExpenses(state, payload) {
      console.log("M -> loadExpenses", payload);
      state.expenses = state.functions.safeCopy(payload.expenses);
    },
    //Invites
    clearShareInvite(state) {
      state.inviteRequest = "";
    },
    storeInviteRequest(state, payload) {
      //{"f":"Ulrik S. Kofod","s":"123456"}
      //eyJmIjoiVWxyaWsgUy4gS29mb2QiLCJzIjoiMTIzNDU2In0                    b64
      //0nI2UDNzITMiojIzJCLiQ2bm92Sg4yUgsWayxWViojImJye                    rev
      //MG5JMlVETnpJVE1pb2pJekpDTGlRMmJtOTJTZzR5VWdzV2F5eFdWaW9qSW1KeWU    d-b64
      console.log("M -> storeInviteRequest", payload);
      try {
        state.inviteRequest = JSON.parse(
          atob([...atob(payload)].reverse().join(""))
        );
      } catch {
        console.error("Bad signup request:", payload);
      }
    },
  },
  actions: {
    //shares
    async createShare({ state, commit, dispatch }, payload) {
      let returned_result = {
        return_code: "warning",
        return_message_txt: "share ikke oprettet",
      };

      delete payload.shareID;

      const request = { function: "saveShare", data: payload };
      await state.functions.callBackend(request).then((result) => {
        if (result.return_code === "success") {
          result.payload = payload;
          commit("createShare", result);
          dispatch("loadShares");
          result.return_message_txt = "Share oprettet";
        }
        returned_result = result;
      });
      return returned_result;
    },
    async loadShares({ state, commit, getters, dispatch }) {
      let returned_result = {
        return_code: "warning",
        return_message_txt: "ingen share hentet",
      };
      const request = {
        function: "listShares",
        data: { id: getters.userId },
      };
      await state.functions.callBackend(request).then((result) => {
        if (result.return_code === "success") {
          commit("loadShares", result);
          dispatch("loadParticipants");
          dispatch("loadExpenses");
          result.return_message_txt = "Shares hentet";
        }
        returned_result = result;
      });
      return returned_result;
    },
    async joinShare({ state, getters, dispatch }, payload) {
      console.log(state.shares, payload);
      let returned_result = {
        return_code: "failure",
        return_message_txt: "Kunne ikke tilføje dig",
      };

      payload.participant = {
        id: payload.userId,
        name: getters.userName,
      };

      const request = {
        function: "joinShare",
        data: payload,
      };
      await state.functions.callBackend(request).then((result) => {
        returned_result = result;
        if (result.return_code === "success") {
          dispatch("loadShares");
          result.return_message_txt = "Du blev tilføjet.";
        }
      });
      return returned_result;
    },
    //expenses
    async addExpense({ state, commit }, payload) {
      let returned_result = {
        return_code: "warning",
        return_message_txt: "udgift tilføjet",
      };
      const request = {
        function: "saveExpense",
        data: payload,
      };
      await state.functions.callBackend(request).then((result) => {
        if (result.return_code === "success") {
          commit("addExpense", payload);
          result.return_message_txt = "Udgift gemt";
        }
        returned_result = result;
      });
      return returned_result;
    },
    async loadExpenses({ state, commit }) {
      let returned_result = {
        return_code: "warning",
        return_message_txt: "ingen udgifter hentet",
      };
      const request = {
        function: "listExpenses",
        data: { shares: state.shares.map((s) => s.shareID) },
      };
      await state.functions.callBackend(request).then((result) => {
        if (result.return_code === "success") {
          commit("loadExpenses", result);
          result.return_message_txt = "Udgifter hentet";
        }
        returned_result = result;
      });
      return returned_result;
    },
    //invites & participants
    clearShareInvite({ commit }) {
      commit("clearShareInvite");
    },
    storeInviteRequest({ commit }, payload) {
      commit("storeInviteRequest", payload);
    },
    async loadParticipants({ state, dispatch }) {
      console.log("@ loadParticipants", state.shares);
      let ids = [];
      state.shares.map((s) =>
        s.participants.map((p) => {
          if (ids.indexOf(p.id) === -1) ids.push(p.id);
        })
      );

      dispatch("user/loadProfiles", ids, { root: true });
    },
  },
  getters: {
    //share
    getShareList: (state) => state.shares,
    getShareListSorted: (state, getters) => {
      return state.shares.sort((a, b) =>
        getters.getMaxExpenseDate(a) > getters.getMaxExpenseDate(b) ? -1 : 1
      );
    },
    getName: () => (share, id) => {
      const p = share.participants.find((p) => {
        return p.id === id;
      });
      //if (p.name === "<virtual>") return p.id;
      return p.name;
    },
    //expenses
    getMaxExpenseDate: (state, getters) => (share) => {
      const expenses = getters.getExpenseList(share);
      console.log("getMaxExpenseDate->", expenses);
      return Math.max(...expenses.map((e) => e.date));
    },
    getExpenseList: (state) => (share) =>
      state.expenses.filter((e) => {
        return e.shareID === share.shareID;
      }),
    getShareTotal: (state, getters) => (share) => {
      let total = getters.getExpenseList(share).reduce(
        (a, b) => {
          if (b.to === undefined) return { amount: a.amount + b.amount };
          else return { amount: a.amount };
        },
        { amount: 0 }
      );
      return total.amount.toFixed(2);
    },
    getParticipantTotal: (state, getters) => (share, participant) => {
      let expenses = getters.getExpenseList(share).reduce(
        (a, b) => {
          if (b.to === undefined)
            return {
              from: a.from,
              amount:
                a.amount +
                (b.amount / share.participants.length -
                  (b.from === a.from ? b.amount : 0)),
            };
          else return a;
        },
        { from: participant.id, amount: 0 }
      );

      let adjust = getters.getExpenseList(share).reduce((a, b) => {
        if (b.to !== undefined)
          return {
            from: a.from,
            amount:
              a.amount +
              (b.to === a.from ? b.amount : b.from === a.from ? -b.amount : 0),
          };
        else return a;
      }, expenses);

      return adjust.amount.toFixed(2);
    },
    //invites
    getInviteRequest: (state) => state.inviteRequest,
    getInviteURI: (state, getters) => (payload) => {
      const randKey = Math.random().toString(36).substring(2, 5);
      const invite = { f: getters.userName, s: payload };
      return window.location.origin
        .concat(
          "?signup=",
          btoa([...btoa(JSON.stringify(invite))].reverse().join(""))
        )
        .concat("&k=", randKey);
    },
    //getters from other modules
    userId: (state, getters, rootState, rootGetters) => {
      return rootGetters["user/getUserId"];
    },
    userName: (state, getters, rootState, rootGetters) => {
      return rootGetters["user/getShownName"];
    },
  },
};

export default shares;
