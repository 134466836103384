import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

import { ToastPlugin } from "bootstrap-vue";
[ToastPlugin].forEach((comp) => {
  Vue.use(comp);
});

const notificationsState = {
  namespaced: true,
  state: {
    messages: [],
  },
  mutations: {
    addMessage(state, message) {
      state.messages.push(message);
    },
    clearMessage(state, messageId) {
      state.messages.push(messageId);
    },
  },
  actions: {
    addMessage({ commit }, payload) {
      console.log("@ addMessage", payload);
      const level = (level) => {
        level = level === "failure" ? "danger" : level;
        const levels = ["danger", "warning", "success", "info"];
        return levels.includes(level) ? level : "info";
      };
      const message = {
        id: Math.random().toString(36) /* unique'ish id*/,
        level: level(payload.level) /* error, warning, success or info*/,
        text: payload.messageText /* text to be displayed */,
      };
      if (payload.level !== "debug" || process.env.NODE_ENV !== "production") {
        payload.vue.$bvToast.toast(message.text, {
          title: message.level, //+ ' - ' + message.id,
          variant: message.level,
          autoHideDelay: 5000,
          appendToast: true,
          toaster: "b-toaster-bottom-right",
        });
      }
      commit("addMessage", message);
    },
    clearMessage({ commit }, payload) {
      commit("clearMessage", payload.messageId);
    },
  },
  getters: {
    getMessages: (state) => state.messages,
  },
};

export default notificationsState;
