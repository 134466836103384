<template>
  <div v-if="!loggedIn" class="home">
    <Home :msg="'Release ' + version" :invited="showSignUp" />
  </div>
  <div v-else c lass="home">
    <ListSharesView />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import Home from "@/components/general/Home.vue";
const ListSharesView = () =>
  import(/* webpackChunkName: "ListSharesView" */ "@/views/ListSharesView.vue");

import mixin from "@/mixins/tools";

export default {
  name: "HomeView",
  mixins: [mixin],
  components: {
    Home,
    ListSharesView,
  },
  computed: {
    showSignUp() {
      const parms = { ...this.$route.query };
      if ("signup" in parms) {
        this.storeInviteRequest(parms.signup);
        return true;
      } else return false;
    },
    ...mapGetters("user", ["loggedIn"]),
  },
  methods: {
    ...mapActions("shares", ["storeInviteRequest"]),
  },
  mounted() {
    console.log(process.env);
  },
};
</script>

<style scoped>
.home {
  min-height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
