import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/help",
    name: "help",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "HelpView" */ "../views/HelpView.vue"),
  },
  {
    path: "/faq",
    name: "faq",
    component: () =>
      import(/* webpackChunkName: "FaqView" */ "../views/FaqView.vue"),
  },
  {
    path: "/about",
    name: "about",
    alias: "/gdpr",
    component: () =>
      import(/* webpackChunkName: "AboutView" */ "../views/AboutView.vue"),
  },
  {
    path: "/news",
    name: "news",
    alias: "/nyheder",
    component: () =>
      import(/* webpackChunkName: "NewsView" */ "../views/NewsView.vue"),
  },
  {
    path: "/profile",
    name: "profile",
    component: () =>
      import(/* webpackChunkName: "ProfileView" */ "../views/ProfileView.vue"),
  },
  {
    path: "/listshares",
    name: "listShares",
    component: () =>
      import(
        /* webpackChunkName: "ListSharesView" */ "../views/ListSharesView.vue"
      ),
  },
  {
    path: "/listexpenses",
    name: "listExpenses",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "ListExpensesView" */ "../views/ListExpensesView.vue"
      ),
  },
  {
    path: "/printshare",
    name: "printshare",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "PrintExpensesView" */ "@/views/PrintExpensesView.vue"
      ),
  },
  {
    path: "/createshare",
    name: "createshare",
    component: () =>
      import(
        /* webpackChunkName: "CreateShareView" */ "@/views/CreateShareView.vue"
      ),
  },
  {
    path: "/receivedinvite",
    name: "receivedinvite",
    component: () =>
      import(
        /* webpackChunkName: "ReceivedInviteView" */ "@/views/ReceivedInviteView.vue"
      ),
  },
  {
    path: "/shareinvite",
    name: "shareinvite",
    component: () =>
      import(
        /* webpackChunkName: "ShareInviteView" */ "@/views/ShareInviteView.vue"
      ),
  },
  {
    path: "/reset",
    name: "reset",
    alias: "/resetPassword",
    component: () =>
      import(
        /* webpackChunkName: "ResetPasswordView" */ "../views/ResetPasswordView.vue"
      ),
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
    //redirect: "/defaultview",
    children: [
      {
        path: "defaultview" /* changed */,
        name: "defaultview",
        component: HomeView,
      },
      {
        path: "*",
        component: HomeView,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
