<template>
  <b-card
    title="Login"
    class="m-auto"
    style="max-width: 25rem"
    footer=""
    footer-tag="footer"
  >
    <b-card-body>
      <b-form @submit="onSubmit">
        <div id="prevent autofill" style="display: none">
          <input id="name" type="text" />
          <input id="password" type="password" />
        </div>
        <b-row>
          <b-col class="m-auto">
            <b-form-group
              id="input-group-1"
              label=""
              label-for="input-1"
              description=""
            >
              <b-form-input
                autofocus
                id="input-1"
                name="input-1"
                v-model="form.username"
                type="text"
                placeholder="brugernavn"
                required
                autocomplete="off"
                value="XXXX"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="m-auto">
            <b-form-group
              id="input-group-2"
              label=""
              label-for="input-2"
              description=""
            >
              <b-form-input
                id="input-2"
                name="input-2"
                v-model="form.password"
                type="password"
                placeholder="kode"
                required
                autocomplete="off"
                value="xxxxxx"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="m-auto">
            <b-button type="submit" class="login">Login</b-button>
          </b-col>
        </b-row>
      </b-form>
      <b-row>
        <b-col class="m-auto">
          <router-link to="/reset" class="reset">
            nulstil glemt kode
          </router-link>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "LoignComponent",
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
    };
  },
  computed: {
    ...mapGetters("user", ["getShownName", "validUsername"]),
    ...mapGetters("shares", ["getInviteRequest"]),
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      this.logIn({
        username: this.form.username,
        password: this.form.password,
      })
        .then((response) => {
          console.log(response);

          this.addMessage({
            vue: this,
            level: response.return_code,
            messageText: response.return_message_txt,
          });
          if (response.return_code === "success") {
            console.log("stored", this.getInviteRequest);
            if (typeof this.getInviteRequest === "object") {
              this.addMessage({
                vue: this,
                level: "success",
                messageText: "Du er blevet inviteret til en share",
              });
              this.$router.push({ name: "receivedinvite" });
            } else if (this.getShownName === "")
              this.$router.push({ name: "profile" });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          console.log("theEnd");
        });
    },
    ...mapActions("user", ["logIn"]),
    ...mapActions("notifications", ["addMessage"]),
  },
  mounted() {},
};
</script>

<!-- 
    font-family: "Special Elite", "IBM Plex Mono", monospace;
-->
<style scoped>
.login {
  font-size: 1.2rem;
  font-weight: bolder;
  color: #005cbf;
  background-color: white;
  padding: 10px;
}
.reset {
  color: #005cbf;
  font-size: 0.8rem;
  font-weight: bolder;
  padding: 10px;
}
</style>
