<template>
  <div class="hello">
    <no-cookies />
    <div class="welcome">
      <img class="logo" alt="logo" src="@/assets/logo.png" />
      <h1>Velkommen til</h1>
      <h2>WeShareToo {{ msg }}</h2>
    </div>
    <div v-if="!loggedIn">
      <b-row v-if="invited">
        <b-col class="invited">
          <p>
            Du er blevet inviteret til at deltage i en WeShareToo af
            <br /><b>{{ getInviteRequest.f || "!! noget gik galt !!" }}</b
            ><br />Hvis du allerede har et login, skal du vælge login.
          </p>
        </b-col>
      </b-row>
      <b-button pill :pressed.sync="showLogin" variant="primary">
        {{ showLogin ? "Opret login" : "Login" }}
      </b-button>
      <div v-if="showLogin" class="mt-3 mb-2">
        <Login />
      </div>
      <div v-else class="mt-3 mb-2">
        <SignUp />
      </div>
    </div>
    <p class="reload my-4">
      <b-link
        replace
        target="WeShareTooFacebook"
        href="https://www.facebook.com/WeShareToo"
        >Følg WeShareToo på facebook<b-icon icon="BIconFacebook"
      /></b-link>
    </p>
    <div class="info_block">
      <p class="info">
        WeShareToo.dk er et innovativt online værktøj, der forenkler
        udgiftdeling mellem grupper af mennesker. <br /><br />Platformen gør det
        nemt at beregne og følge udgifter delt mellem venner, roommates,
        kolleger eller andre, der deler omkostninger regelmæssigt.
        <br /><br />Med WeShareToo.dk kan du nemt oprette en gruppe, tilføje
        udgifter og se, hvor meget hver deltager skylder eller er skyldig i
        realtid. <br /><br />Uanset om du deler husleje, dagligvareindkøb eller
        rejseudgifter, tager WeShareToo.dk besværet ud af udgiftdeling, så du
        kan fokusere på at nyde din tid med andre. <br /><br />Tilmeld dig i dag
        og begynd at forenkle din udgiftdeling!
      </p>
      <ul>
        <li>
          Man kan afregne nemt, helt eller delvist, via mobilePay, PayPal eller
          kontant.
        </li>
        <li>Dem du deler med behøver ikke bruge appen.</li>
        <li>
          Du kan udskrive opgørelsen med totaler og detaljer til PDF, så du kan
          dele den på mail eller print.
        </li>
        <li>Der er ingen krav om opdatering af persionilge oplysninger.</li>
        <li>Ingen vilkår der skal accepteres.</li>
        <li>Ingen reklamer.</li>
        <li>Ingen cookis som spore dine aktiviteter på siden.</li>
        <li>Læs mere i "Om" og "Nyheder".</li>
      </ul>
    </div>
    <p class="reload" v-if="isMobile">
      Det er en god ide at lukke og genåbne siden for at være sikker på det er
      den seneste version.
    </p>
    <p class="reload" v-else>
      Det er en god ide at trykke ctrl+shift+r <br />for at være sikker på det
      er den seneste version du har læst ind.
    </p>
  </div>
</template>

<script>
import Login from "@/components/user/Login.vue";
import { mapGetters } from "vuex";

const SignUp = () =>
  import(/* webpackChunkName: "SignUp" */ "@/components/user/SignUp.vue");

import NoCookies from "@/components/general/noCookies.vue";
import mixin from "@/mixins/tools";

export default {
  name: "HomeComponent",
  components: { Login, SignUp, NoCookies },
  mixins: [mixin],
  props: {
    msg: String,
    invited: Boolean,
  },
  data() {
    return {
      showLogin: true,
    };
  },
  computed: {
    ...mapGetters("user", ["loggedIn"]),
    ...mapGetters("shares", ["getInviteRequest"]),
  },
  async mounted() {
    if (this.invited) this.showLogin = !this.invited;
    console.log("mounted Home");
  },
  created() {
    console.log("created Home");
  },
};
</script>

<style scoped>
.welcome {
  font-family: "Special Elite", "IBM Plex Mono", monospace;
  background-color: #fff;
  padding-bottom: 10px;
  margin: auto;
}
.info_block {
  border: #eee solid 1px;
  margin: 4vw;
  padding: 1vw;
}
.info {
  font-family: "IBM Plex Mono", monospace;
  background-color: #fff;
  margin: 20px;
  text-align: justify;
}
.invited {
  color: #fff;
  background-color: #0a7;
  font-weight: bolder;
  padding: 10px;
  margin-bottom: 10px;
}
.logo {
  margin: 5px;
  width: 180px;
  height: 42px;
}

p {
  hyphens: auto;
}

div {
  padding: 0;
  margin: 0;
}
h1,
h5,
h3,
h4 {
  letter-spacing: 3px;
  font-weight: bold;
  margin: 0;
}
ul {
  font-family: "IBM Plex Mono", monospace;
  margin: 20px;
  display: none;
}
li {
  padding: 0;
  margin: auto;
  text-align: left;
}
a {
  color: #047326;
  font-weight: bold;
}
h2 {
  letter-spacing: 1px;
  font-weight: bold;
  margin: auto;
  font-size: medium;
  text-align: center;
  text-overflow: clip;
  padding: 0;
}
.reload {
  font-family: "Special Elite", "IBM Plex Mono", monospace;

  margin: auto;
  font-size: smaller;
  font-weight: lighter;
  letter-spacing: 1px;
  text-align: center;
  text-overflow: clip;
}
</style>
