import axios from "axios";

class ManagerProfile {
  constructor(profile) {
    this.shownName = profile.shownName || "";
    this.email = profile.email || "";
    this.phoneno = profile.phoneno || "";
    this.paypalme = profile.paypalMe || "";
  }
}
class Profile {
  constructor() {
    this.name = "";
    this.key = "";
    this.profile = new ManagerProfile({});
  }
}

const userState = {
  namespaced: true,
  state: {
    participantList: [],
    profile: new Profile(),
    backendResponse: {
      return_code: "",
      return_message: "",
      return_message_txt: "",
    },
    functions: {
      callBackend: async (payload) => {
        let data = {};
        payload.source = "WeShareToo";
        console.log("F -< callBackend", payload);
        const target =
          process.env.NODE_ENV !== "production"
            ? "https://ws2.cybersite.dk"
            : "";

        await axios
          .post(target + "/api/user/" + payload.function, payload)
          .then((result) => {
            console.log("functions.callBackend", result);
            data = result.data;
          })
          .catch((err) => {
            console.log("Der skete en fejl!", err);
            console.log(err.response);
            data = err;
            data.return_code = "failure";
          });
        return data;
      },
    },
  },
  mutations: {
    async resetResult(state) {
      state.backendResponse = {
        return_code: "",
        return_message: "",
        return_message_txt: "",
      };
    },
    async logOut(state) {
      console.log("M logOut->");
      state.profile = new Profile();
      console.log("M <- logOut", state.profile);
    },
    async logIn(state, payload) {
      console.log("M logIn->", payload);
      state.backendResponse = payload;
      if (payload.return_code === "success") {
        state.profile.key = payload.key;
        state.profile.name = payload.user_name;
        if (payload.profile)
          state.profile.profile = new ManagerProfile(payload.profile);
        state.backendResponse.return_message_txt = "Bruger konto verificeret";
      } else {
        if (
          payload.return_message === "wrong_user_or_password" ||
          payload.return_message === "user_not_found"
        ) {
          state.backendResponse.return_message_txt =
            "Forkert brugernavn eller kode";
        } else {
          console.log("E @ M -> logIn", payload);
          state.backendResponse.return_message_txt =
            "Der opstod et problem. " + payload.message;
        }
      }
      console.log("M <- logIn", state.profile);
    },
    async signUp(state, payload) {
      console.log("M signUp->", payload);
      state.backendResponse = payload;
      if (payload.return_code === "success") {
        state.profile.key = payload.key;
        state.profile.name = payload.user_name;
        state.profile.profile.profileText = "";
        state.profile.profile.managerName = "";

        state.backendResponse.return_message_txt =
          "Brugerkontoen blev oprettet.";
      } else {
        if (payload.return_message === "user_already_exist") {
          state.backendResponse.return_message_txt =
            "Brugerkontoen findes allerede.";
        } else {
          state.backendResponse.return_message_txt = "Der opstod et problem.";
        }
      }
    },
    async deleteUser(state, payload) {
      console.log("M deleteProfile->", payload);
      //payload = { return_code: "success" }; //testing

      state.backendResponse = payload;
      if (payload.return_code === "success") {
        state.profile = new Profile();
        state.backendResponse.return_message_txt = "Profilen blev slettet.";
      } else {
        state.backendResponse.return_message_txt = "Der opstod et problem.";
      }
    },
    async profile(state, payload) {
      console.log("M profile->", payload);
      state.backendResponse = payload;
      if (payload.return_code === "success") {
        state.profile.profile = new ManagerProfile(payload.user.profile);
        state.backendResponse.return_message_txt = "Profilen blev opdateret.";
      } else {
        state.backendResponse.return_message_txt = "Der opstod et problem.";
      }
    },
    async setParticipantList(state, payload) {
      state.backendResponse = payload;
      if (payload.return_code === "success") {
        state.participantList = payload.userList;
        state.backendResponse.return_message_txt = "Deltager navne hentet.";
      } else {
        state.backendResponse.return_message_txt =
          "Problem: Kunne ikke hente deltagere.";
      }
    },
    async resetPassword(state, payload) {
      state.backendResponse = payload;
      if (payload.return_code === "success") {
        state.backendResponse.return_message_txt =
          "Du kan nu logge ind med din nye kode.";
      } else {
        state.backendResponse.return_message_txt =
          "Problem: Din nye kode kunne ikke oprettes";
      }
    },
    async resetPasswordRequest(state, payload) {
      state.backendResponse = payload;
      if (payload.return_code === "success") {
        state.backendResponse.return_message_txt =
          "Du modtager en mail med en kode, hvis det er den rigtige mail adresse du har har angivet.";
      } else {
        state.backendResponse.return_message_txt =
          "Problem: Mail kunne ikke sendes.";
      }
    },
  },
  actions: {
    async logOut({ commit }) {
      commit("logOut");
    },
    async logIn({ state, commit }, payload) {
      console.log("@ logIn->", payload);
      let returned_result = [];
      const login = {
        function: "checkAccess",
        userName: payload.username,
        password: payload.password,
      };
      commit("resetResult");
      await state.functions.callBackend(login).then((result) => {
        commit("logIn", result);
        returned_result = result;
      });
      return returned_result;
    },
    async signUp({ state, commit }, payload) {
      console.log("@ signUp->", payload);
      let returned_result = [];
      const signUp = {
        function: "createUser",
        userName: payload.username,
        password: payload.password,
      };
      commit("resetResult");
      await state.functions.callBackend(signUp).then((result) => {
        commit("signUp", result);
        returned_result = result;
      });
      return returned_result;
    },

    async deleteProfile({ state, commit }, payload) {
      const profile = {
        function: "deleteUser",
        key: state.profile.key,
        userName: state.profile.name,
        password: payload.password,
      };

      commit("resetResult");
      await state.functions.callBackend(profile).then((result) => {
        commit("deleteUser", result);
      });

      return state.backendResponse;
    },
    async profile({ state, commit }, payload) {
      console.log("@ profile->", payload);
      let returned_result = [];
      const profile = {
        function: "updateProfile",
        key: state.profile.key,
        profile: { ...payload },
      };

      delete profile.profile.password;

      if (payload.password.length) {
        profile.password = payload.password;
      }

      console.log(payload.username + " !== " + state.profile.name);
      if (payload.username !== state.profile.name) {
        profile.userName = payload.username;
      }

      commit("resetResult");
      await state.functions.callBackend(profile).then((result) => {
        commit("profile", result);
        returned_result = result;
      });
      return returned_result;
    },
    async clearResult({ commit }) {
      commit("resetResult");
    },
    async loadProfiles({ state, commit }, payload) {
      console.log("@ loadProfiles->");

      let returned_result = [];
      const loadList = {
        function: "listProfiles",
        ids: payload,
      };

      commit("resetResult");
      await state.functions.callBackend(loadList).then((result) => {
        commit("setParticipantList", result);
        returned_result = result;
      });
      return returned_result;
    },
    async resetPassword({ state, commit }, payload) {
      console.log("@ resetPassword->");

      let returned_result = [];
      const loadList = {
        function: "resetPassword",
        data: payload,
      };
      console.log("@ resetPassword->", loadList);

      commit("resetResult");
      await state.functions.callBackend(loadList).then((result) => {
        commit("resetPassword", result);
        returned_result = result;
      });
      return returned_result;
    },
    async resetPasswordRequest({ state, commit }, payload) {
      console.log("@ resetPasswordRequest->");
      const templates = require("@/assets/data/mailTemplates.json");

      let returned_result = [];
      const loadList = {
        function: "sendResetRequest",
        email: payload.email,
        mailData: templates.resetPassword,
        senderInfo: {
          from: "reset_password@wesharetoo.dk",
          fromName: "Reset Password på wesharetoo.dk",
          replyTo: "support@wesharetoo.dk",
          replyToName: "Support",
        },
      };

      commit("resetResult");
      await state.functions.callBackend(loadList).then((result) => {
        commit("resetPasswordRequest", result);
        returned_result = result;
      });
      return returned_result;
    },
  },
  getters: {
    //getters for current user
    getUserId: (state) => state.profile.key,
    getUserName: (state) => state.profile.name,
    getShownName: (state) =>
      state.profile.profile.shownName || state.profile.name,
    getEmailAddress: (state) => state.profile.profile.email,
    getPhoneNumber: (state) => state.profile.profile.phoneno,
    getPayPalMe: (state) => state.profile.profile.paypalme,
    loggedIn: (state) => state.profile.key != "",
    response: (state) => state.backendResponse,

    //getters for participant
    getParticipantPhoneNo: (state) => (id) => {
      const p = state.participantList.find((p) => p.id === id);
      if (typeof p === "object") return p.profile.phoneno;
      return "";
    },
    getParticipantPayPal: (state) => (id) => {
      const p = state.participantList.find((p) => p.id === id);
      if (typeof p === "object") return p.profile.paypalMe;
      return "";
    },
    getParticipantList: (state) => state.participantList,

    //Useful validation functions
    validEmail: () => (email) => {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    validPayPal: () => (paypal) => {
      const re = /^[a-z0-9]{2,20}$/i;
      return re.test(paypal);
    },
    validPasswd: () => (password) => {
      const re = /^(?=.*[a-z])(?=.*[A-Z])((?=.*\d)|(?=.*[\W])).{6,}$/;
      return re.test(password);
    },
    validUsername: () => (username) => {
      const re = /^(?=.*[A-Za-z]).{5,}$/;
      return re.test(username);
    },
    validPhoneNo: () => (phoneno) => {
      //const re = /^(?=.*[\d]).{8}$/;
      const re = /^[\d]{8}$/;

      return re.test(phoneno);
    },
  },
};

export default userState;
