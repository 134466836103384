<template>
  <img class="noCookie" alt="NO COOKIES" src="img/noCookies.png" />
</template>

<script>
export default {
  setup() {},
  template:
    "<img class='noCookie' alt='NO COOKIES' src='img/noCookies.png' width='110px' height='92' />",
};
</script>

<style lang="css" scoped>
.noCookie {
  z-index: -20000;
  width: 55px;
  height: 46px;
  position: absolute;
  left: 0px;
  top: 0px;
}
</style>
