import Vue from "vue";
import Vuex from "vuex";
import userState from "./modules/user.js";
import notificationsState from "./modules/notifications.js";
import sharesState from "./modules/shares.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    user: userState,
    notifications: notificationsState,
    shares: sharesState,
  },
});
