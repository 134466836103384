import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

// bootstrap and bootstrap-vue ----->
import "./app.scss";

import {
  ButtonPlugin,
  CardPlugin,
  CollapsePlugin,
  FormCheckboxPlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormPlugin,
  FormSelectPlugin,
  FormTextareaPlugin,
  ImagePlugin,
  InputGroupPlugin,
  LayoutPlugin,
  LinkPlugin,
} from "bootstrap-vue";
[
  ButtonPlugin,
  CardPlugin,
  CollapsePlugin,
  FormCheckboxPlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormPlugin,
  FormSelectPlugin,
  FormTextareaPlugin,
  InputGroupPlugin,
  ImagePlugin,
  LayoutPlugin,
  LinkPlugin,
].forEach((comp) => {
  Vue.use(comp);
});

import {
  BIcon,
  BIconChatLeftDots,
  BIconChevronUp,
  BIconChevronDown,
  BIconEnvelope,
  BIconFacebook,
  BIconFilePdf,
  BIconFiles,
  BIconInfoCircle,
  BIconLink,
  BIconPhone,
  BIconPrinter,
  BIconShare,
  BIconUpcScan,
} from "bootstrap-vue";
[
  ["BIcon", BIcon],
  ["BIconChatLeftDots", BIconChatLeftDots],
  ["BIconChevronUp", BIconChevronUp],
  ["BIconChevronDown", BIconChevronDown],
  ["BIconEnvelope", BIconEnvelope],
  ["BIconFacebook", BIconFacebook],
  ["BIconFilePdf", BIconFilePdf],
  ["BIconFiles", BIconFiles],
  ["BIconInfoCircle", BIconInfoCircle],
  ["BIconLink", BIconLink],
  ["BIconPhone", BIconPhone],
  ["BIconPrinter", BIconPrinter],
  ["BIconShare", BIconShare],
  ["BIconUpcScan", BIconUpcScan],
].forEach((comp) => {
  Vue.component(comp[0], comp[1]);
});

import VueQRCodeComponent from "vue-qrcode-component";
Vue.component("qr-code", VueQRCodeComponent);

// VBTooltipPlugin, TooltipPlugin
import { VBTooltip, VBToggle, VBPopover } from "bootstrap-vue";
// Note: Vue automatically prefixes the directive name with 'v-'
Vue.directive("b-tooltip", VBTooltip);
Vue.directive("b-toggle", VBToggle);
Vue.directive("b-popover", VBPopover);
//** */ <----- bootstrap and bootstrap-vue

Vue.config.productionTip = false;

var myVue = Vue.extend({});

new myVue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

/*
Unused Components  & Icons (saved for later)

LayoutPlugin,
ButtonPlugin,
ButtonToolbarPlugin,
ButtonGroupPlugin,
CardPlugin,
FormPlugin,
FormGroupPlugin,
FormInputPlugin,
ToastPlugin,
NavbarPlugin,
FormSelectPlugin,
AlertPlugin,
FormTextareaPlugin,
OverlayPlugin,
PopoverPlugin,
FormSpinbuttonPlugin,
TableSimplePlugin,

BIconLock,
BIconHouse,
BIconEyeglasses,
BIconBriefcaseFill,
BIconCheck2Square,
BIconGrid3x2Gap,
BIconCardChecklist,
BIconCheck2All,
BIconCalendarWeek,
BIconListOl,
BIconDiagram2,
BIconNewspaper,
BIconPeople,
BIconFileEarmarkPerson,
BIconPersonCircle,
BIconTrophy,
BIconBoxArrowInRight,
BIconUnlock,
BIconCheckSquare,
BIconSquare,
BIconChevronUp,
BIconChevronDown,
BIconArrowUp,
BIconArrowDown,
BIconList,
BIconFileSpreadsheetFill,


["BIcon", BIcon],
["BIconLock", BIconLock],
["BIconHouse", BIconHouse],
["BIconEyeglasses", BIconEyeglasses],
["BIconBriefcaseFill", BIconBriefcaseFill],
["BIconCheck2Square", BIconCheck2Square],
["BIconGrid3x2Gap", BIconGrid3x2Gap],
["BIconCardChecklist", BIconCardChecklist],
["BIconCheck2All", BIconCheck2All],
["BIconCalendarWeek", BIconCalendarWeek],
["BIconListOl", BIconListOl],
["BIconDiagram2", BIconDiagram2],
["BIconNewspaper", BIconNewspaper],
["BIconPeople", BIconPeople],
["BIconFileEarmarkPerson", BIconFileEarmarkPerson],
["BIconPersonCircle", BIconPersonCircle],
["BIconTrophy", BIconTrophy],
["BIconBoxArrowInRight", BIconBoxArrowInRight],
["BIconUnlock", BIconUnlock],
["BIconCheckSquare", BIconCheckSquare],
["BIconSquare", BIconSquare],
["BIconChevronUp", BIconChevronUp],
["BIconChevronDown", BIconChevronDown],
["BIconArrowUp", BIconArrowUp],
["BIconArrowDown", BIconArrowDown],
["BIconList", BIconList],
["BIconFileSpreadsheetFill", BIconFileSpreadsheetFill],

*/
