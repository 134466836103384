<template>
  <div id="app">
    <nav>
      | <b-link to="/">Forside</b-link> | <b-link to="/help">Hjælp</b-link> |
      <b-link to="/faq">FAQ</b-link> | <b-link to="/news">Nyheder</b-link> |
      <b-link to="/about">Om</b-link> |
      <div v-if="loggedIn" style="display: inline">
        <b-link to="/profile" v-if="loggedIn"> Profil</b-link> |
        <b-link to="#" v-if="loggedIn" @click="systemLogOut">Log ud </b-link> |
      </div>
    </nav>
    <router-view />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters("user", ["loggedIn"]),
  },
  methods: {
    systemLogOut() {
      this.logOut().then(() => {
        this.$router.replace({ name: "home" });
        window.location.reload();
      });
    },
    ...mapActions("user", ["logOut"]),
  },
  created() {
    if (process.env.NODE_ENV === "production") {
      this.oldConsoleLog = console.log;
      window["console"]["log"] = function () {};
    }

    this.$router.beforeEach((to, from, next) => {
      if (
        !(
          to.name === "home" ||
          to.name === "help" ||
          to.name === "faq" ||
          to.name === "news" ||
          to.name === "reset" ||
          to.name === "about"
        ) &&
        !this.loggedIn
      )
        next({ name: "home" });
      else next();
    });
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #047326;
}

nav a.router-link-exact-active {
  color: #2309a3;
}
</style>
